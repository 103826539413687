import { FunctionComponent as FC } from 'react'
import './spinner.css'
interface Props {}

const Spinner: FC<Props> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.1)',
        flex: 1,
        height: '100vh',
      }}
    >
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner
