const configs = {
  baseUrl: process.env.REACT_APP_API_URL,
  clientId: process.env.REACT_APP_CLIENT_ID,
  clientSecret: process.env.REACT_APP_CLIENT_SECRET,
  grantType: process.env.REACT_APP_GRANT_TYPE,
  persistSecretKey: process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY || 'defaultSecretKey',
  storageKey: 'AppIsosware',
}

export default configs
