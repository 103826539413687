import { FC } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useAppSelector } from 'redux-core/hooks'

type Configs = {
  omitRedirects: string[]
  redirectTo: string
}

const WrapperComponent: FC<{ configs?: Configs }> = (props) => {
  const { pathname } = useLocation()
  const token = useAppSelector((s) => s?.oAuth.token)

  if (token && pathname === '/login') {
    return <Redirect to="home" />
  } else if (!token && pathname !== '/login') {
    return <Redirect to="/login" />
  }
  return <>{props.children}</>
}

type Wrapper = {
  <T>(props: FC<T>): FC<T>
}

type WithAuth = {
  (configs?: Configs): Wrapper
}

const withAuth: WithAuth = (configs) => (Component) => (props) => {
  return (
    <WrapperComponent configs={configs}>
      <Component {...props} />
    </WrapperComponent>
  )
}

export default withAuth
