import React from 'react'
import SvgFoto from '../../assets/images/Foto'
import SvgLogo from '../../assets/images/Logo'
import SvgTuerca from '../../assets/images/Tuerca'
import styles from './menuSuperior.module.css'

import { Dropdown, Row, Col } from 'antd'
import MenuEmpresas from './Sections/MenuEmpresas'
import SvgArrowDown from '../icons/ArrowDown'
import SvgCampana from '../icons/Campana'
import { MenuTuerca } from './Sections/MenuTuerca'
import { MenuNotification } from './Sections/MenuNotification'
import { MenuPerfil } from './Sections/MenuPerfil'
import useSubsidiary from 'services/subsidiary.service'
import useConstruction from 'services/construction.service'
import { MenuRouteView } from './Sections/MenuRouteView'
import { useAppSelector } from 'redux-core/hooks'

const MenuSuperior = () => {
  const subsidiary = useAppSelector((state) => state?.subsidiary.current)
  const apiSubsidiary = useSubsidiary()

  const [arrEmpresas, setArrEmpresas] = React.useState<
    | {
        id: number
        ruc: string
        razonSocial: string
        direccionFiscal: string
        actividadEconomica: string
        numeroContacto: string
      }[]
    | null
  >(null)

  const [empresaSelected, setEmpresaSelected] = React.useState<{
    id: number
    ruc: string
    razonSocial: string
    direccionFiscal: string
    actividadEconomica: string
    numeroContacto: string
  } | null>(null)

  const [flagEmpresasDropped, setFlagEmpresasDropped] = React.useState(false)
  const [flagTuercaDropped, setFlagTuercaDropped] = React.useState(false)
  const [flagNotificationDropped, setFlagNotificationDropped] = React.useState(false)
  const [flagPerfilDropped, setFlagPerfilDropped] = React.useState(false)

  const [colorTuercaFill, setColorTuercaFill] = React.useState('#595555')
  const [colorCampanaFill, setColorCampanaFill] = React.useState('#595555')

  // const [infoUsuario, setInfoUsuario] = React.useState(getSessionStorage('infouser'))

  React.useEffect(() => {
    const getEmpresas = async () => {
      const subsidiarias = await apiSubsidiary.getSubsidiaries()
      setArrEmpresas(
        subsidiarias.data.items
          .filter((x: any) => x.id !== subsidiary.id)
          .map((x: any) => {
            return {
              id: x.id,
              ruc: x.document,
              razonSocial: x.rz_social,
              direccionFiscal: x.address,
              actividadEconomica: x.economic_activity,
              numeroContacto: x.contact_number,
            }
          })
      )
      const subsidiariaSelected = subsidiarias.data.items.find((x: any) => x.id === subsidiary.id)
      setEmpresaSelected({
        id: subsidiariaSelected.id,
        ruc: subsidiariaSelected.document,
        razonSocial: subsidiariaSelected.rz_social,
        direccionFiscal: subsidiariaSelected.address,
        actividadEconomica: subsidiariaSelected.economic_activity,
        numeroContacto: subsidiariaSelected.contact_number,
      })
    }
    getEmpresas()
  }, [subsidiary])

  // React.useEffect(() => {
  //   const getSedeEspecifica = async () => {
  //       if(__slidedContext.idSedeProyecto) {
  //         const sedeEspecifica = await apiConstruction.getSedesProyectosById(
  //           __slidedContext.idSedeProyecto.toString()
  //         )
  //         if (sedeEspecifica) {
  //           setNameSedeSeleccionada(sedeEspecifica.data.name)
  //         }
  //       }
  //   }
  //     getSedeEspecifica()
  // }, [__slidedContext.idSedeProyecto])

  // React.useEffect(() => {
  //   const routeName = ModulesConstant.__dictionary[ModulesConstant.OFAD].subModulos.find(
  //     (x) => x.ruta === router.pathname
  //   )?.module
  //   if (routeName) {
  //     setNameRoute(routeName)
  //   }
  // }, [router])

  return (
    <nav className={styles.navigation}>
      <div className={styles.navContainer}>
        <Row align="middle" className="h-full">
          <Col span={16}>
            {/* <div className="w-48 sm:w-52 md:w-60"> */}
            <div className="flex flex-row items-center">
              <div className="py-2">
                {empresaSelected && (
                  <Dropdown
                    visible={flagEmpresasDropped}
                    trigger={['click']}
                    overlay={
                      <MenuEmpresas
                        handleClose={() => setFlagEmpresasDropped(false)}
                        items={arrEmpresas}
                      />
                    }
                    className={styles.comboEmpresa}
                  >
                    <div
                      className={styles.combEmpContainer}
                      onClick={() => setFlagEmpresasDropped((prev) => !prev)}
                    >
                      <div className="flex flex-row">
                        <SvgLogo />
                        <div className={styles.containerTexto}>
                          <span className={styles.textoNombreComp}>
                            {empresaSelected.razonSocial}
                          </span>
                          <span className={styles.textoRUC}>RUC {empresaSelected.ruc}</span>
                        </div>
                      </div>
                      <div className={styles.containerArrow}>
                        <SvgArrowDown className="ml-4" />
                      </div>
                    </div>
                  </Dropdown>
                )}
              </div>
              <MenuRouteView />
            </div>
          </Col>
          <Col span={8}>
            <Row justify="end" align="middle">
              <Col span={24}>
                <div className="flex flex-row justify-end">
                  <Dropdown
                    visible={flagTuercaDropped}
                    placement="bottomRight"
                    overlay={
                      <MenuTuerca
                        infoEmpresa={empresaSelected}
                        handleClose={() => setFlagTuercaDropped(false)}
                      />
                    }
                  >
                    <div
                      className={styles.tabsContainer}
                      onMouseOver={() => setColorTuercaFill('#FFFFFF')}
                      onMouseOut={() => setColorTuercaFill('#595555')}
                      onClick={() => setFlagTuercaDropped((prev) => !prev)}
                    >
                      <SvgTuerca fill={colorTuercaFill} />
                    </div>
                  </Dropdown>
                  <Dropdown
                    visible={flagNotificationDropped}
                    placement="bottomRight"
                    overlay={
                      <MenuNotification handleClose={() => setFlagNotificationDropped(false)} />
                    }
                  >
                    <div
                      className={styles.tabsContainer}
                      onMouseOver={() => setColorCampanaFill('#FFFFFF')}
                      onMouseOut={() => setColorCampanaFill('#595555')}
                      onClick={() => setFlagNotificationDropped((prev) => !prev)}
                    >
                      <SvgCampana fill={colorCampanaFill} />
                    </div>
                  </Dropdown>
                  <Dropdown
                    visible={flagPerfilDropped}
                    placement="bottomRight"
                    overlay={<MenuPerfil handleClose={() => setFlagPerfilDropped(false)} />}
                  >
                    <div
                      onClick={() => setFlagPerfilDropped((prev) => !prev)}
                      className={styles.tabsContainer}
                    >
                      <SvgFoto />
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </nav>
  )
}

export default MenuSuperior
