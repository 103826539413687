import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RightOutlined } from '@ant-design/icons'
import * as ModulesConstant from 'constants/modules'

export const MenuRouteView = () => {
  const router = useLocation()

  const construction: { id: number; nameConstruction: string } = useSelector<any>(
    (state) => state.construction.current
  )
  const [nameSedeSeleccionada, setNameSedeSeleccionada] = React.useState()
  const [nameRoute, setNameRoute] = React.useState('')

  React.useEffect(() => {
    const routeName = ModulesConstant.__dictionary[ModulesConstant.OFAD].subModulos.find(
      (x) => x.ruta === router.pathname
    )?.module
    if (routeName) {
      setNameRoute(routeName)
    }
  }, [router])

  return (
    <div>
      {construction.nameConstruction && (
        <div className="flex flex-row items-center">
          <span className="mx-4 truncate max-w-[250px] text-sm">
            {construction.nameConstruction}
          </span>
          <RightOutlined style={{ color: '#FFB900' }} />
          <span className="mx-4 text-sm">{nameRoute}</span>
        </div>
      )}
    </div>
  )
}
