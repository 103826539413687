import * as React from 'react'

interface Props {
  className?: string
  width?: string
  height?: string
  fill: string
}

function SvgCampana(props: Props): JSX.Element {
  return (
    <svg
      width={props.width ? props.width : '1.5em'}
      height={props.height ? props.height : '1.5em'}
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M17.333 20.668h-12v-8.667c0-3.333 2.667-6 6-6 3.334 0 6 2.667 6 6v8.667zM20 19.335V12c0-4.093-2.853-7.52-6.667-8.426v-.907a2 2 0 10-4 0v.907C5.507 4.48 2.667 7.908 2.667 12v7.334L0 22v1.334h22.667V22L20 19.335zm-8.667 8A2.667 2.667 0 0014 24.668H8.667a2.667 2.667 0 002.666 2.667z"
        fill={props.fill}
      />
    </svg>
  )
}

export default SvgCampana
