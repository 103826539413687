import * as React from 'react'

interface Props {
  className?: string
  width?: string
  height?: string
  fill?: string
}

function SvgTuerca(props: Props): JSX.Element {
  // const [colorFill, setColorFill] = React.useState(props.fill || '#595555')
  return (
    <svg
      width={props.width ? props.width : '1.5em'}
      height={props.height ? props.height : '1.5em'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      // onMouseOver={() => (props.hoverColor ? setColorFill(props.hoverColor) : null)}
      // onMouseOut={() => setColorFill(props.fill || '#595555')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.668 13.142c.047-.373.082-.747.082-1.143 0-.397-.035-.77-.082-1.144L23.13 8.93a.589.589 0 00.14-.746l-2.334-4.037a.586.586 0 00-.711-.257L17.32 5.057a8.524 8.524 0 00-1.972-1.143L14.905.822a.569.569 0 00-.572-.49H9.666a.569.569 0 00-.571.49L8.65 3.914A8.963 8.963 0 006.68 5.057L3.775 3.89a.57.57 0 00-.712.257L.73 8.184a.575.575 0 00.14.746l2.461 1.925A9.25 9.25 0 003.25 12c0 .385.035.77.081 1.143L.87 15.067a.589.589 0 00-.14.747l2.333 4.036c.14.257.455.35.712.257L6.68 18.94c.606.467 1.26.852 1.971 1.144l.444 3.091c.035.28.28.49.571.49h4.667c.292 0 .537-.21.572-.49l.443-3.091a8.965 8.965 0 001.972-1.144l2.905 1.167a.57.57 0 00.711-.257l2.334-4.036a.589.589 0 00-.14-.747l-2.462-1.925zM12 16.082A4.088 4.088 0 017.917 12 4.088 4.088 0 0112 7.915 4.088 4.088 0 0116.083 12 4.088 4.088 0 0112 16.082z"
        fill={props.fill || '#595555'}
      />
    </svg>
  )
}

export default SvgTuerca
