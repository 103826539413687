import React from 'react'
import SvgHamburguesa from '../../assets/images/Hamburguesa'
import styles from './sideBar.module.css'
import { MainTab } from './sections'
import SvgArrowLeft from '../icons/ArrowLeft'
import { SlidedContext } from '../../hooks/useContextSlide'
import { ModulesConstant } from '../../constants'
import { useSelector } from 'react-redux'

const SideBar = () => {
  const __slidedContext = React.useContext(SlidedContext)
  const construction: { id: number; nameConstruction: string } = useSelector<any>(
    (state) => state.construction.current
  )

  const [moduloDropped, setModuloDropped] = React.useState('')

  return (
    <div className={styles.sideContainer}>
      <div className={__slidedContext.slided ? styles.sidebar_slided : styles.sidebar}>
        {construction.id && (
          <div className={styles.sideBtnSlide}>
            <div>
              <SvgArrowLeft
                handleChange={() => __slidedContext.setSlided(true)}
                className={__slidedContext.slided ? 'hidden' : 'cursor-pointer'}
              />
            </div>
            <SvgHamburguesa
              handleChange={() => __slidedContext.setSlided(false)}
              className={__slidedContext.slided ? 'cursor-pointer' : 'hidden'}
            />
          </div>
        )}
        <div className={__slidedContext.slided ? styles.ocultarTabs : styles.mostrarTabs}>
          <MainTab
            dropped={moduloDropped === ModulesConstant.OFAD}
            handleChange={() => {
              moduloDropped === ModulesConstant.OFAD
                ? setModuloDropped('')
                : setModuloDropped(ModulesConstant.OFAD)
            }}
            texto={ModulesConstant.OFAD}
            subTabs={ModulesConstant.__dictionary[ModulesConstant.OFAD].subModulos}
          />
          <MainTab
            dropped={moduloDropped === ModulesConstant.SEPR}
            handleChange={() => {
              moduloDropped === ModulesConstant.SEPR
                ? setModuloDropped('')
                : setModuloDropped(ModulesConstant.SEPR)
            }}
            texto={ModulesConstant.SEPR}
          />
          <MainTab
            dropped={moduloDropped === ModulesConstant.ISOS}
            handleChange={() => {
              moduloDropped === ModulesConstant.ISOS
                ? setModuloDropped('')
                : setModuloDropped(ModulesConstant.ISOS)
            }}
            texto={ModulesConstant.ISOS}
          />
        </div>
      </div>
    </div>
  )
}

export default SideBar
