import SvgArrowDown from 'components/icons/ArrowDown'
import SvgArrowDrop from 'components/icons/ArrowDrop'
import React from 'react'
import styles from '../sideBar.module.css'

import SubTab from './subTab'

interface Props {
  texto?: string
  subTabs?: {
    nombre: string
    ruta: string
    module: string
  }[]
  dropped: boolean
  handleChange: () => void
  moduleHandleChange?: (e: string) => void
}

const MainTab = (props: Props): JSX.Element => {
  const [subModuleSelected, setSubModuleSelected] = React.useState('')

  return (
    <div>
      <div className={styles.sideTabs}>
        <div className={props.dropped ? styles.sideTabsContainerDroped : styles.sideTabsContainer}>
          <div className={styles.sideTabConCenter} onClick={props.handleChange}>
            <span className={styles.tabTexto}>{props.texto}</span>
            <div className={styles.arrowContainer}>
              <SvgArrowDown className={props.dropped ? 'hidden' : ''} />
              <SvgArrowDrop className={props.dropped ? '' : 'hidden'} />
            </div>
          </div>
        </div>
      </div>
      <div className={props.dropped ? styles.mostrarSubTabs : styles.ocultarSubTabs}>
        {props.subTabs && (
          <div className={styles.subSideTabs}>
            {props.subTabs.map((x, k) => (
              <SubTab
                key={k}
                item={x}
                selected={subModuleSelected}
                handleChange={() => {
                  if (props.moduleHandleChange) {
                    props.moduleHandleChange(x.module)
                  }
                  setSubModuleSelected(x.nombre)
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default MainTab
