import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import config from 'constants/config'

const middleware = [thunk]

export const createReduxStore = () => {
  const persistconfig: PersistConfig<{}> = {
    key: config.storageKey,
    storage,
    // transforms: [
    //   encryptTransform({
    //     secretKey: config.persistSecretKey,
    //   }),
    // ],
  }

  const persistRedurer = persistReducer(persistconfig, rootReducer)
  const store = createStore(persistRedurer, composeWithDevTools(applyMiddleware(...middleware)))
  const persistor = persistStore(store)
  return { store, persistor }
}
