import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'

export interface Page extends RouteProps {
  fullLayout?: boolean
}

const pagesAuth: Page[] = [
  {
    component: lazy(() => import('pages/login')),
    path: '/login',
    fullLayout: true,
  },
  {
    component: lazy(() => import('pages/registro')),
    path: '/register',
    fullLayout: true,
  },
  {
    component: lazy(() => import('pages/recuperar-cuenta')),
    path: '/recuperar-cuenta',
    fullLayout: true,
  },
]

const pagesApp: Page[] = [
  {
    component: lazy(() => import('pages/app/home')),
    path: '/home',
  },
  {
    component: lazy(() => import('pages/app/notifications')),
    path: '/notifications',
  },
  {
    component: lazy(() => import('pages/app/perfil')),
    path: '/perfil',
  },
  {
    component: lazy(() => import('pages/app/personal')),
    path: '/personal',
  },
]
const pages = [...pagesAuth, ...pagesApp]

export default pages
