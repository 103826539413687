import * as React from 'react'
interface Props {
  className: string
  handleChange: () => void
}

function SvgHamburguesa(props: Props): JSX.Element {
  return (
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.handleChange}
      className={props.className}
    >
      <circle r={16} transform="matrix(-1 0 0 1 16 16)" fill="#DBDBDB" />
      <path
        d="M9.991 10.176a1.25 1.25 0 100 2.5v-2.5zm12.5 2.5a1.25 1.25 0 000-2.5v2.5zm-12.5 0h12.5v-2.5h-12.5v2.5zM9.991 14.602a1.25 1.25 0 100 2.5v-2.5zm12.5 2.5a1.25 1.25 0 000-2.5v2.5zm-12.5 0h12.5v-2.5h-12.5v2.5zM9.991 18.602a1.25 1.25 0 100 2.5v-2.5zm12.5 2.5a1.25 1.25 0 000-2.5v2.5zm-12.5 0h12.5v-2.5h-12.5v2.5z"
        fill="#8E8484"
      />
    </svg>
  )
}

export default SvgHamburguesa
