import React from 'react'
import MenuSuperior from 'components/MenuSuperior'
import SideBar from 'components/SideBar'
import { SlidedContext } from 'hooks/useContextSlide'
import styles from './Home.module.css'

interface Props {
  children?: React.ReactNode
}

const HomeLayout = (props: Props): JSX.Element => {
  const __slidedContext = React.useContext(SlidedContext)

  return (
    <div>
      <MenuSuperior />
      <SideBar />
      <div
        className={
          __slidedContext.slided ? styles.contenidoContainerSlided : styles.contenidoContainer
        }
      >
        <main>{props.children}</main>
      </div>
    </div>
  )
}

export default HomeLayout
