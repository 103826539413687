import * as Yup from 'yup'
import { es } from 'yup-locales'

Yup.setLocale(es)
/*TODO: Pasa a TypeScript */

Yup.addMethod(
  Yup.string,
  'alphaNumeric',
  function (length, msg = 'Solo estan permitidos caracteres alfanuméricos') {
    return this.test({
      name: 'alpha-numeric',
      message: msg,
      test: (value: any) => {
        const regex = /^[a-z\d\-_.,;.\s]+$/i
        return regex.test(value)
      },
    })
  }
)

Yup.addMethod(
  Yup.string,
  'alphaSpaces',
  function (length, msg = 'Solo estan permitidos letras y espacios') {
    return this.test({
      name: 'alpha-spaces',
      message: msg,
      test: (value: any) => {
        const regex = /^[a-zA-Z \-_.,;ó!?]*$/
        return regex.test(value)
      },
    })
  }
)

Yup.addMethod(Yup.string, 'phoneNumber', function (length, msg = 'Número de contacto incorrecto') {
  return this.test({
    name: 'phone-number',
    message: msg,
    test: (value: any) => {
      const regex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
      return regex.test(value)
    },
  })
})

export default Yup
