import { useRef, createContext, FC } from 'react'
import { ModalIsos, IModalParams } from 'components/Modal'

const initialValues = {
  showModal: (params: IModalParams) => {},
}

export const Context = createContext(initialValues)

export const AppContext: FC<{}> = (props) => {
  const refModal = useRef<any>()

  const showModal = (params: IModalParams) => {
    refModal.current?.showModal(params)
  }
  return (
    <Context.Provider value={{ showModal }}>
      {props.children}
      <ModalIsos ref={refModal} />
    </Context.Provider>
  )
}

export default AppContext
