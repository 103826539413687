import { combineReducers } from 'redux'
import constructionReducer from '../reducers/construction.reducer'
import subsidiaryReducer from '../reducers/subsidiary.reducer'
import authReducer from '../reducers/auth.reducer'

import storage from 'redux-persist/lib/storage'
import config from 'constants/config'
import { REMOVE_TOKEN } from 'redux-core/actions/auth.action'

const appReducer = combineReducers({
  oAuth: authReducer,
  subsidiary: subsidiaryReducer,
  construction: constructionReducer,
})

export type RootState = ReturnType<typeof appReducer> | undefined

const rootReducer = (state: any, action: any) => {
  if (action.type === REMOVE_TOKEN) {
    state = undefined
    storage.removeItem(`persist:${config.storageKey}`)
  }

  return appReducer(state, action)
}

export default rootReducer
