import 'assets/styles/global.scss'
import 'antd/dist/antd.css'
import { FunctionComponent as FC } from 'react'
import Navigation from './navigation'
import { createReduxStore } from 'redux-core/store'
import { Provider } from 'react-redux'
import AppContext from './context/AppContext'
import { PersistGate } from 'redux-persist/integration/react'

const { store, persistor } = createReduxStore()

const App: FC<{}> = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppContext>
          <Navigation />
        </AppContext>
      </PersistGate>
    </Provider>
  )
}

export default App
