import { combineReducers } from 'redux'
import { SAVE_CURRENT_SUBSIDIARY } from '../actions/subsidiary.action'

const initialState = {}

const currentSubsidiary = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CURRENT_SUBSIDIARY:
      return { id: action.payload.id }
    default:
      return state
  }
}

export default combineReducers({
  current: currentSubsidiary,
})
