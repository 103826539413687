import { combineReducers } from 'redux'
import { SAVE_CURRENT_CONTRUCTION } from '../actions/construction.action'

const initialState = {}

const currentConstruction = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CURRENT_CONTRUCTION:
      return { id: action.payload.id, nameConstruction: action.payload.name }
    default:
      return state
  }
}

export default combineReducers({
  current: currentConstruction,
})
