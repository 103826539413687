export const OFAD = 'Oficina administrativa'
export const SEPR = 'Sedes - Proyectos'
export const ISOS = 'ISOSware TV'

export const __dictionary = {
  [OFAD]: {
    nombre: OFAD,
    subModulos: [
      {
        nombre: 'Centro de notificaciones',
        ruta: '/app/notifications',
        module: 'Centro de notificaciones ',
      },
      { nombre: 'Documentos', ruta: '/app/documents', module: 'Documentos ' },
      { nombre: 'Gestión de RRHH', ruta: '/app/personal', module: 'RRHH' },
      { nombre: 'Registro de actividad', ruta: '/app/activity', module: '' },
      { nombre: 'Eventos', ruta: '/app/event', module: '' },
      { nombre: 'Gestión logística', ruta: '/app/logistic', module: '' },
      { nombre: 'Simulacros', ruta: '/app/simulation', module: '' },
      { nombre: 'Maquinaria y equipo', ruta: '/app/equipment', module: '' },
      { nombre: 'ISOs', ruta: '/app/isos', module: '' },
      { nombre: 'SIG', ruta: '/app/sig', module: '' },
      { nombre: 'Bioseguridad', ruta: '/app/biosecurity', module: '' },
      { nombre: 'Estadísticas', ruta: '/app/statistics', module: '' },
      { nombre: 'Reportes', ruta: '/app/reports', module: '' },
    ],
  },
}

export const __array = [OFAD, SEPR, ISOS]
