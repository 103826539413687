import React from 'react'
import styles from '../menuSuperior.module.css'
import { Menu } from 'antd'

import { ModalNewRUC } from 'components/Modal/ModalNewRUC'
import { useAppContext } from 'hooks'
import SvgLogo from 'assets/images/Logo'

interface Props {
  items:
    | {
        id: number
        ruc: string
        razonSocial: string
        direccionFiscal: string
        actividadEconomica: string
        numeroContacto: string
      }[]
    | null
  handleClose: () => void
}

const MenuEmpresas = (props: Props): JSX.Element => {
  const { showModal } = useAppContext()

  return (
    <>
      <Menu style={{ marginTop: '10px' }} onClick={props.handleClose}>
        {props.items &&
          props.items.map((x, k) => (
            <Menu.Item key={k} style={{ paddingLeft: 0 }}>
              <div className={styles.menuCombEmpContainer}>
                <div className="flex flex-row">
                  <SvgLogo />
                  <div className={styles.containerTexto}>
                    <span className={styles.textoNombreComp}>{x.razonSocial}</span>
                    <span className={styles.textoRUC}>{x.ruc}</span>
                  </div>
                </div>
              </div>
            </Menu.Item>
          ))}
        <Menu.Divider />
        <Menu.Item key={props.items ? props.items.length + 1 : 1}>
          <div
            className={styles.menuCombEmpContainer}
            onClick={() => {
              showModal({
                title: 'Registro de empresa',
                Content: ModalNewRUC,
                extraParams: null,
              })
            }}
          >
            <div className={styles.containerTexto}>
              <span className={styles.textoNombreComp}>Agregar RUC</span>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    </>
  )
}

export default MenuEmpresas
