import React from 'react'
import styles from './modal.module.scss'
import { Button } from 'antd'

import { InfoCircleFilled } from '@ant-design/icons'

const BaseModal = ({ message, hideModal }) => {
  return (
    <div className={styles.BaseModal}>
      <div className={styles.content}>
        <InfoCircleFilled className={styles.iconInfo} />
        <p className={styles.text}>{message}</p>
      </div>

      <div className={styles.confirmBtns}>
        <div>
          <Button
            size="middle"
            type="primary"
            onClick={() => {
              hideModal()
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BaseModal
