import { Suspense, FC } from 'react'
import { Router, Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import withAuth from 'utils/withAuth'
import pages, { Page } from './pages'
import HomeLayout from 'components/Layouts/LayoutApp/HomeLayout'
import Spinner from 'components/Spinner'

export let history = createBrowserHistory()

const Wrapper: FC<{ page: Page }> = (props) => {
  const { page, children } = props
  if (!page?.fullLayout) {
    return <HomeLayout>{children}</HomeLayout>
  }
  return <>{children}</>
}

const AppRouteWrapper = ({ component, ...page }: Page) => {
  return (
    <Route
      {...page}
      render={(props) => {
        const Component = component as any
        return (
          <Wrapper page={page}>
            <Suspense fallback={<Spinner />}>
              <Component {...props} />
            </Suspense>
          </Wrapper>
        )
      }}
    />
  )
}
const AppRoute = withAuth({
  redirectTo: '/login',
  omitRedirects: ['/register', '/recuperar-cuenta'],
})(AppRouteWrapper)

interface Props {}

const Navigation: FC<Props> = (props) => {
  return (
    <Router history={history}>
      <Switch>
        {pages.map((params, index) => (
          <AppRoute key={index.toString()} {...params} />
        ))}
        <Redirect path="/" to="/home" />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}

function NotFound() {
  let location = useLocation()
  return (
    <div>
      <h3>
        No se encontro la página<code>{location.pathname}</code>
      </h3>
    </div>
  )
}

export default Navigation
