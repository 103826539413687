import React from 'react'

export type SlidedContent = {
  idSedeProyecto: number | null
  setIdSedeProyecto: React.Dispatch<React.SetStateAction<number | null>>
  slided: boolean
  setSlided: React.Dispatch<React.SetStateAction<boolean>>
  idSubsidiaria: number | null
  setIdSubsidiaria: React.Dispatch<React.SetStateAction<number | null>>
}

const SlidedContext = React.createContext<SlidedContent>({
  slided: true,
  idSedeProyecto: null,
  idSubsidiaria: null,
  setIdSubsidiaria: () => {},
  setIdSedeProyecto: () => {},
  setSlided: () => {},
})

const { Provider } = SlidedContext

interface Props {
  children: JSX.Element
}

const SlidedProvider = (props: Props): JSX.Element => {
  const [slided, setSlided] = React.useState(true)
  const [idSedeProyecto, setIdSedeProyecto] = React.useState<number | null>(null)
  const [idSubsidiaria, setIdSubsidiaria] = React.useState<number | null>(null)

  return (
    <Provider
      value={{
        slided: slided,
        setSlided: setSlided,
        idSedeProyecto: idSedeProyecto,
        setIdSedeProyecto: setIdSedeProyecto,
        idSubsidiaria: idSubsidiaria,
        setIdSubsidiaria: setIdSubsidiaria ,
      }}
    >
      <>{props.children}</>
    </Provider>
  )
}

export { SlidedContext, SlidedProvider }
