import { SET_TOKEN, REMOVE_TOKEN, SET_INFO_USER } from '../actions/auth.action'

const initialValues = {
  token: null,
  userInfo: null,
}
const authReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case REMOVE_TOKEN:
      return {
        ...state,
        token: undefined,
      }
    case SET_INFO_USER:
      return {
        ...state,
        userInfo: action.payload,
      }
    default:
      return state
  }
}

export default authReducer
