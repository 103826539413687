import * as React from "react";

function SvgArrowDown(props?: { className?: string }) {
  return (
    <svg
      width="0.75em"
      height="0.75em"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.035c.004.06.004.076.014.135a1.003 1.003 0 00.278.538l6 6c.045.041.055.053.103.09a1.002 1.002 0 001.209 0c.048-.037.059-.049.103-.09l6-6c.041-.045.053-.055.09-.103A1.005 1.005 0 0013.903.57a1.008 1.008 0 00-.8-.565 1.004 1.004 0 00-.707.198c-.049.036-.059.048-.103.09L7 5.585 1.707.293l-.05-.046c-.047-.039-.058-.05-.11-.083A1.002 1.002 0 00.015.831C.004.89.004.906 0 .966v.069z"
        fill="#747474"
      />
    </svg>
  );
}

export default SvgArrowDown;
