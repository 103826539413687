import * as React from "react";

function SvgArrowDrop(props?: { className?: string }) {
  return (
    <svg
      width="0.75em"
      height="0.75em"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.965c.004-.06.004-.075.014-.135a1.003 1.003 0 01.278-.538l6-6c.045-.041.055-.053.103-.09a1.002 1.002 0 011.209 0c.048.037.059.049.103.09l6 6c.041.045.053.055.09.103a1.005 1.005 0 01.106 1.034c-.149.312-.457.53-.8.565a1.004 1.004 0 01-.707-.198c-.049-.036-.059-.048-.103-.09L7 2.415 1.707 7.707l-.05.046c-.047.039-.058.05-.11.083a1.002 1.002 0 01-1.533-.667c-.01-.06-.01-.075-.014-.135v-.069z"
        fill="#747474"
      />
    </svg>
  );
}

export default SvgArrowDrop;
