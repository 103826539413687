import React from 'react'
import { Form, Input } from 'formik-antd'
import { Row, Col, Button } from 'antd'
import { Formik } from 'formik'
import Yup from 'utils/validation'
import SvgLogo from 'assets/images/Logo'

interface Props {
  extraParams:
    | {
        id: number
        ruc: string
        razonSocial: string
        direccionFiscal: string
        actividadEconomica: string
        numeroContacto: string
      }
    | null
    | undefined
}

export const ModalNewRUC = (props: Props): JSX.Element => {
  const formInitialValues = React.useMemo(() => {
    return {
      ruc: props.extraParams ? props.extraParams.ruc : '',
      razonSocial: props.extraParams ? props.extraParams.razonSocial : '',
      direccionFiscal: props.extraParams ? props.extraParams.direccionFiscal : '',
      actividadEconomica: props.extraParams ? props.extraParams.actividadEconomica : '',
      numeroContacto: props.extraParams ? props.extraParams.numeroContacto : '',
    }
  }, [props])

  const EmpresaSchema = Yup.object().shape({
    ruc: Yup.string().min(11).max(15).required(),
    razonSocial: Yup.string().min(3).max(100).required(),
    direccionFiscal: Yup.string().min(5).max(30).required(),
    actividadEconomica: Yup.string().min(3).max(30).required(),
    numeroContacto: Yup.string().min(9).max(9).required(),
  })

  const clickRegistrar = React.useCallback((data) => {
    console.log(data)
  }, [])

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={EmpresaSchema}
      onSubmit={clickRegistrar}
    >
      <Form layout="vertical">
        <Row>
          <Col span={4}>
            <div className="w-full flex flex-row items-center justify-start">
              <div className="border border-gray-mid rounded-full w-20 h-20 flex items-center justify-center">
                <SvgLogo width="3em" height="3em" />
              </div>
            </div>
          </Col>
          <Col span={9}>
            <div className="flex flex-col ml-4">
              <Button size="large" type="primary">
                Cargar logo
              </Button>
              <span className="text-xxs mt-2">
                La imagen debe de tener un tamano de 340px x 150px
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="py-5">
              <span className="font-bold text-black">Datos de la empresa</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={20}>
                <Form.Item label="Ruc" name="ruc">
                  <Input name="ruc" placeholder="Ej: 20605082905"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={20}>
                <Form.Item label="Razon social" name="razonSocial">
                  <Input name="razonSocial" placeholder="Ej: ISO'S WARE S.A.C."></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={20}>
                <Form.Item label="Direccion fiscal" name="direccionFiscal">
                  <Input name="direccionFiscal" placeholder="Ej: AV. CIRCUNVALACION CLUB.."></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={20}>
                <Form.Item label="Actividad economica" name="actividadEconomica">
                  <Input name="actividadEconomica" placeholder="Ej: textil"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={20}>
                <Form.Item label="Numero de contacto" name="numeroContacto">
                  <Input name="numeroContacto" placeholder="Ej: 999 999 999"></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {props.extraParams && (
            <Col span={12}>
              <Row>
                <Col span={20}>
                  <div className="flex w-full h-full items-start pt-7">
                    <Button danger type="primary" size="large" block>
                      Desactivar RUC
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    </Formik>
  )
}
