import * as React from "react";

interface Props {
  className?: string;
  handleChange?: () => void;
}

function SvgArrowLeft(props: Props): JSX.Element {
  return (
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.handleChange}
      className={props.className}
    >
      <circle cx={16} cy={16} r={16} fill="#DBDBDB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.965 9c-.06.004-.075.004-.135.014a1.003 1.003 0 00-.538.278l-6 6c-.041.045-.053.055-.09.104a1 1 0 000 1.208c.037.048.049.059.09.103l6 6c.045.041.055.053.103.09a1.005 1.005 0 001.034.106 1.008 1.008 0 00.524-1.206 1.003 1.003 0 00-.157-.301c-.036-.049-.048-.059-.09-.103L14.415 16l5.293-5.293.046-.05c.038-.047.05-.058.083-.11a1 1 0 00-.666-1.533c-.06-.01-.076-.01-.136-.014h-.069z"
        fill="#747474"
      />
    </svg>
  );
}

export default SvgArrowLeft;
