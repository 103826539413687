import React from 'react'
import styles from '../menuSuperior.module.css'

import { Menu, Row, Col, Divider } from 'antd'

interface Props {
  handleClose: () => void
}

export const MenuNotification = (props: Props): JSX.Element => {
  return (
    <Menu style={{ marginTop: '10px' }} onClick={props.handleClose}>
      <Menu.Item key={1} style={{ height: '100%' }}>
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-start">
            <span className="text-sm text-gray-dark">SAC 002 - Evaluar</span>
            <span className={styles.dot}></span>
            <span className="text-xxs text-gray-dark leading-sm">Hace un momento</span>
          </div>
          <div className="flex items-center justify-start">
            <span className="text-xxs text-gray-dark leading-sm">Un nuevo documento ha sido agregado</span>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key={2}>
        <span className="text-sm text-gray-dark">Abrir centro de notificaciones</span>
      </Menu.Item>
    </Menu>
  )
}
