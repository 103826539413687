import styles from '../menuSuperior.module.css'
import { Menu } from 'antd'
import { logOut } from 'redux-core/actions/auth.action'
import { useAppDispatch } from 'redux-core/hooks'

interface Props {
  handleClose: () => void
}

export const MenuPerfil = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  return (
    <Menu style={{ marginTop: '10px' }} onClick={props.handleClose}>
      <Menu.Item key={1}>
        <div>
          <span className={styles.menuTuercaText}>Mi cuenta</span>
        </div>
      </Menu.Item>
      <Menu.Item key={2}>
        <div
          onClick={() => {
            dispatch(logOut())
            // location.reload()
          }}
        >
          <span className={styles.menuTuercaText}>Cerrar sesion</span>
        </div>
      </Menu.Item>
    </Menu>
  )
}
