import useBaseResponse from './baseResponse'

const useSubsidiary = () => {
  const baseResponse = useBaseResponse()

  const getSubsidiaries: () => any = () => {
    const url = `/api/subsidiary`

    return baseResponse({
      method: 'GET',
      url,
      validateStatus: (status) => status === 200,
    })
  }

  return {
    getSubsidiaries,
  }
}

export default useSubsidiary
