import React from 'react'
import styles from '../menuSuperior.module.css'

import { Menu } from 'antd'
import { useAppContext } from 'hooks'
import { ModalNewRUC } from 'components/Modal/ModalNewRUC'

interface Props {
  infoEmpresa: {
    id: number
    ruc: string
    razonSocial: string
    direccionFiscal: string
    actividadEconomica: string
    numeroContacto: string
  } | null
  handleClose: () => void
}

export const MenuTuerca = (props: Props): JSX.Element => {
  const { showModal } = useAppContext()

  return (
    <>
      <Menu style={{ marginTop: '10px' }} onClick={props.handleClose}>
        <Menu.Item key={1}>
          <div
            onClick={() => {
              showModal({
                title: 'Actualizar empresa',
                Content: ModalNewRUC,
                extraParams: props.infoEmpresa,
              })
            }}
          >
            <div>
              <span className={styles.menuTuercaText}>Actualizar RUC</span>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item key={2}>
          <div>
            <div>
              <span className={styles.menuTuercaText}>Eliminar RUC</span>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    </>
  )
}
