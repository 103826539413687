import queryString from 'query-string'
import { api } from './axios'
import { logOut } from 'redux-core/actions/auth.action'
import { useAppContext } from 'hooks'
import { useAppDispatch, useAppStore } from 'redux-core/hooks'

type Params = {
  url: string
  method: 'POST' | 'GET' | 'DELETE' | 'PATCH'
  params?: { [key: string]: any }
  data?: any
  validateStatus: (status: number) => boolean
  withCredentials?: boolean
  headers?: any
}

interface BaseResponse extends Params {
  token?: string
}

const messageError = 'Ocurrió un error, intente nuevamente'
const messageUnauthorized = 'Su sesión a caducado'

const baseResponse = ({ withCredentials = true, ...params }: BaseResponse) => {
  const url = `${params.url}${params.params ? `?${queryString.stringify(params.params)}` : ''}`
  const headers = withCredentials
    ? { Authorization: `Bearer ${params.token}`, ...params.headers }
    : {
        ...params.headers,
      }
  console.log('headers', headers)
  return api({
    url,
    method: params.method,
    headers,
    data: params.data,
    validateStatus: () => true,
  })
}

const useBaseResponse = () => {
  const store = useAppStore()
  const { showModal } = useAppContext()
  const dispatch = useAppDispatch()
  return (params: Params) =>
    new Promise<any>((resolve, reject) => {
      baseResponse({ ...params, token: store.getState()?.oAuth.token }).then((response) => {
        const dataService = response.data
        if (params.validateStatus(response.status)) {
          resolve(dataService)
        } else if (response.status === 401) {
          dispatch(logOut())
          showModal({
            message: dataService?.error?.message || messageUnauthorized,
          })
          reject(dataService.error)
        } else {
          showModal({
            message: dataService?.error?.message || messageError,
          })
          reject(dataService.error)
        }
      })
    })
}
export default useBaseResponse
