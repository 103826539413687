import axios from 'axios'
import { config } from '../constants'

axios.defaults.baseURL = config.baseUrl

const api = axios.create({
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.eject = (e) => {
  console.log('interceptors', e)
  return e
}

export { api }
