export const SET_TOKEN = 'SET_TOKEN'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const GET_TOKEN = 'GET_TOKEN'
export const SET_INFO_USER = 'SET_INFO_USER'
export const REMOVE_SET_INFO_USER = 'REMOVE_SET_INFO_USER'

export const setAuth = (payload: any) => {
  return {
    type: SET_TOKEN,
    payload,
  }
}

export const logOut = () => {
  return {
    type: REMOVE_TOKEN,
  }
}

export const setInfoUser = (payload: any) => {
  return {
    type: SET_INFO_USER,
    payload,
  }
}

export const removeInfoUser = (payload: any) => {
  return {
    type: REMOVE_SET_INFO_USER,
    payload,
  }
}
