import Link from 'components/Link'
import React from 'react'
import styles from '../sideBar.module.css'

interface Props {
  item: {
    nombre: string
    ruta: string
  }
  selected: string
  handleChange?: () => void
}

const SubTab = (props: Props): JSX.Element => {
  return (
    <Link href={props.item.ruta}>
      <div
        className={
          props.selected === props.item.nombre
            ? styles.subSideTabsContainerSelected
            : styles.subSideTabsContainerNoSelected
        }
        onClick={props.handleChange}
      >
        <div className={styles.subSideTabsConCenter}>
          <span className={styles.tabTexto}>{props.item.nombre}</span>
        </div>
      </div>
    </Link>
  )
}

export default SubTab
