import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Modal } from 'antd'
import BaseModal from './BaseModal'
import styles from './modal.module.scss'

export interface IModalParams {
  title?: String
  closeIcon?: React.ReactNode
  Content?: (props: any) => JSX.Element | null
  extraParams?: any
  message?: string
  onAccept?: () => void
  onCancel?: () => void
}

const initialParams: IModalParams = {
  Content: BaseModal,
}

const ModalIsos = ({}, ref) => {
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState(initialParams)

  const showModal = (params: IModalParams) => {
    console.log(params)
    setParams({ ...initialParams, ...params })
    setVisible(true)
  }

  const hideModal = () => {
    setParams(initialParams)
    setVisible(false)
  }

  useImperativeHandle(ref, () => ({ showModal }))
  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      if (visible) {
        alert('bak')
        e.stopPropagation()
        hideModal()
      }
    })

    return () => window.removeEventListener('popstate', () => {})
  }, [visible])

  return (
    <>
      <Modal
        className={styles.modalIsos}
        centered
        visible={visible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={hideModal}
        footer={null}
        {...params}
      >
        {visible && params.Content && <params.Content hideModal={hideModal} {...params} />}
      </Modal>
    </>
  )
}

export default forwardRef(ModalIsos)
